
.slideshow,
.slideshow-blank {
    min-width: 280px;
    aspect-ratio: 2.414 / 1;
    overflow: hidden;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.slideshow {
    background-color: rgb(43 41 41);
}

.slideshow-blank {
    background-color: white;
}

@media screen and (max-width: 700px) {
    .slideshow,
    .slideshow-blank {
        aspect-ratio: 16 / 9;
    }
}

.motion-box {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.motion-box-img {
    width: 100%;
}

.motion-box-text-upper,
.motion-box-text-lower {
    position: absolute;
    background-color: rgb(43 41 41 / 50%);
    color: white;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5px;
}

.motion-box-text-upper {
    top: 5%;
    left: 5%;
}

.motion-box-text-lower {
    bottom: 5%;
    right: 5%;
}

/* @media screen and (max-width: 500px) {

}.motion-box-text-size {

} */

.next,
.prev {
    cursor: pointer;
    font-weight: bold;
    z-index: 2;
}

.next {
    right: 10px;
}

.prev {
    left: 10px;
}