
.not-found {
  width: 80vw;
  text-align: center;
  grid-column: 2;
  margin: 20px;
}

.not-found-card {
  text-align: center;
  padding: 20px;
}


.app {
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns:  1fr 8fr 1fr;
  height: 100vh;
}

@media screen and (max-width: 700px) {
  .app {
    grid-template-columns: 1fr 20fr 1fr;
  }
}

.header {
  grid-row: 1;
  grid-column: 1/4;
  background-color: rgb(43 41 41);
  width: 100%;
  min-height: 10vh;
  height: 50px;
  max-height: 100px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding-top: 10px;
  padding-bottom: 10px
}

.title a {
  text-decoration: none;
}

.wara-header {
  color:white;
}

.sub-head-italic {
  font-style: italic;
}

.headerh1 {
  color: white;
  grid-column: 2;
  font-family: Calibri, sans-serif;
  font-weight: 400;
}

/* .login-header {
  color: white;
  align-self: center;
  justify-self: center;
  grid-column: 3;
} */

.login-header-button {
  color: white;
  place-self: center center;
  align-items: center;
  grid-column: 3;
  padding-right: 2vw;
  float: right;
}


.header-logo-container {
  grid-column: 1;
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-left: 2vw;
}


.header-logo-image {
  /* max-height: inherit; */
  max-height: 100px;
  object-fit: cover;
}

/* Ugly solution, cant get the height to scale properly */
@media screen and (max-width: 700px) {
  .header-logo-image {
    max-width: 60vw;
  }
}

.line-flex {
  grid-column: 1/4;
  grid-row: 3;
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: center;
  align-items: flex-end;
  align-self: end;
}

.before-footer-space {
  width: 100%;
  margin-top: 5vh;
}

@media screen and (min-width: 500px) {
  .footer  {
      display: grid;
      grid-template-columns: auto 1fr auto;
      width: 100%;
    }

    .footer-left {
      grid-column: 1;
      margin-left: 2vw;
    }

    .footer-right {
      grid-column: 3;
      margin-right: 2vw;
    }
}

.footer {
  padding: 0.5vh;
  margin-bottom: 10px
}