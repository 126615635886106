.expcard {
  padding: 20px;
  text-align: justify;
  align-self: center;
  word-wrap: break-word;
  max-width: 1600px;
  margin: auto;
}

.dialog-frame {
  width: 45vw;
  min-width: 220px;
  max-width: 420px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.calendar {
  width: 100%;
}

.jwt-access-token {
  word-wrap: break-word;
  overflow: auto;
  width: 100%;
}

.login-button-group {
  text-align: center;
}

@media screen and (max-width: 700px) {
  .expcard {
    padding: 8px;
  }
}