.spinner-space {
  margin: auto;
  height: 3rem;
}

.table-cell {
  border-left: 1px solid var(--mui-palette-primary-main);
}

/* .table-logo {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.table-logo {
  height: 75px;
  max-width: 100px;
  object-fit: contain;
}
