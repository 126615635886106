body {
  background-color: rgb(255 255 255);

  /* min-width: 1000px; */
}

h1 {
  font-family: Overpass, sans-serif;
  margin-bottom: 0;
}

table {
  width: 100%;
  border-collapse: collapse;

}

tr:nth-child(odd) {
  background-color: #fff;
}
