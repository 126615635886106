
.tabs-header {
  grid-row: 2;
  grid-column: 2;
}

.tabs-icon-menu {
  background-color: white;
  margin-top: 20px;
}

.tabs-body {
  margin-top: 20px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 20px;
}
