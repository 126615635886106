
.spinner-space {
  margin: auto;
  height: 3rem;
  text-align: center;
}



.expcard {
  padding: 20px;
  text-align: justify;
  align-self: center;
  word-wrap: break-word;
  max-width: 1600px;
  margin: auto;
}

@media screen and (max-width: 700px) {
  .expcard {
    padding: 8px;
  }
}

.logo-container {
  height: 100px;
  max-width: 200px;
  object-fit: contain;
}
