

.login-header-button {
  color: white;
  place-self: center center;
  grid-column: 3;
  padding-right: 2vw;
  float: 'right';
}

.button-link a {
  text-decoration: none;
}