
.spinner-space {
  height: 3rem;
}

.text-space {
  margin: 1rem;
  margin-bottom: 2rem;
  height: 1rem;
}

.text-info {
  width: 100%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: left;
}

.text-error-space {
  color: red;
}

.text-ok-space {
  color: green;
}

.text-field {
  margin-bottom: 2px !important;
}

.error-text-items {
  font-size: 0.6rem;
  height:  0.6rem;
  margin-bottom: 6px;
  margin-left: 5px;
  text-align: left;
}

.signup-error-space {
  color: red;
  margin: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  height: 0.8rem;
  font-size: 0.8rem;
}

.secondary-space {
  margin-top: 20px;
  padding: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: center;
}
