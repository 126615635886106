
.flex-item {
  margin-top: 25px;
  grid-row: 2;
  grid-column: 2;
}

.present-space {
  color:rgb(99 57 57);
  font-family: Overpass, sans-serif;

  /* border: 2px red; */
}

.sell-button {
  background-color: rgb(43 41 41);
  width: 100%;
}

.contactfooter {
  width: 100%;
}

.dialog-frame {
  width: 65vw;
  min-width: 220px;
  max-width: 480px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 15px;
}