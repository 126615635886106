.login-redirect-text {
  margin: auto;
  height: 1rem;
  text-align: center;
}

.login-frame {
  width: 35vw;
  min-width: 250px;
  max-width: 350px;
}

.sell-button {
  align-self: center;
}

.login-header {
  color: white;
  place-self: center center;
  grid-column: 3;
}

.login-image-container {
  text-align: center;
  margin-top: 2rem;
}

.login-error-space {
  color: red;
  margin: 5px;
  height: 1rem;
}

.login-input-group {
  margin: 1rem;
}

.login-dialog-input {
 padding: 0.5rem;
}

.login-button-group {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* .alogin-dialog-button {
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin: 10px;
} */

